import { Box, createTheme, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, ThemeProvider, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRef, forwardRef, useEffect, useState } from 'react';
import styles from './style.module.css';

import { FieldDetailProvider } from '../../../context/FieldDetailsContext/FieldDetailsContext';
import ScheduleCalendar from './components/ScheduleCalendar/ScheduleCalendar';
import FieldDetailPageHeader from './components/FieldDetailPageHeader/FieldDetailPageHeader';
import SoilTotal from './components/SoilTotal/SoilTotal';
import { SoilTotalProvider } from '../../../context/FieldDetailsContext/SoilTotalContext';
import CanopyStress from './components/CanopyStress/CanopyStress';
import CanopyStressContextProvider from '../../../context/FieldDetailsContext/CanopyStressContext';
import SoilMoistureProfileProvider from '../../../context/FieldDetailsContext/SoilMoistureProfileContext';
import SoilMoistureProfile from './components/SoilMoistureProfile/SoilMoistureProfile';
import Rootzone from './components/Rootzone/Rootzone';
import RootzoneProvider from '../../../context/FieldDetailsContext/RootzoneContext';
import EtcCropWaterUse from './components/EtcCropWaterUse/EtcCropWaterUse';
import EtcCropWaterUseProvider from '../../../context/FieldDetailsContext/EtcCropWaterUseContext';
import CumulativeDayDegreesProvider from '../../../context/FieldDetailsContext/CumulativeDayDegreesContext';
import CumulativeDayDegrees from './components/CumulativeDayDegrees/CumulativeDayDegrees';
import LocationCard from './components/LocationCard/LocationCard';
import CropCard from './components/CropCard/CropCard';
import RainfallCard from './components/RainfallCard/RainfallCard';
import IrrigationCard from './components/IrrigationCard/IrrigationCard';
import { useAppSelector } from '../../../stores/hooks';
import useFieldDetailContext from '../../../hooks/ContextHooks/useFieldDetailContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DateRangeSection from './components/DateRangeSection/DateRangeSection';
import Notes from './components/Notes/Notes';
import GoannaDrawer from '../../../components/GoannaDrawer/GoannaDrawer';
import FieldDetailHelpDrawer from './components/FieldDetailHelpDrawer/FieldDetailHelpDrawer';
import GoannaDrawerProvider from '../../../context/GoannaDrawerContext/GoannaDrawerContext';

export type itemType = {
    "id": number,
    "primary": string,
    'exist': boolean,
    "show": boolean
};

const theme = createTheme(
    {
        palette: {
            primary: { main: '#0958FF' },
        },
    },
);
export interface IFieldDetailProps {

}

const graphPrefrencesMapping: any = {
    "sch_cal": forwardRef<HTMLDivElement>((props, ref) => <div ref={ref}><ScheduleCalendar {...props} /></div>),
    "soil_tot": forwardRef<HTMLDivElement>((props, ref) =>
        <SoilTotalProvider {...props}>
            <div ref={ref}><SoilTotal /></div>
        </SoilTotalProvider>
    ),
    "stress": forwardRef<HTMLDivElement>((props, ref) =>
        <CanopyStressContextProvider {...props}>
            <div ref={ref}><CanopyStress /></div>
        </CanopyStressContextProvider>
    ),
    "smp&rootzone": forwardRef<HTMLDivElement>((props, ref) =>
        <div {...props} className={styles.RootzoneSoilMoistureProfileFlex} ref={ref}>
            <SoilMoistureProfileProvider>
                <SoilMoistureProfile />
            </SoilMoistureProfileProvider>
            <RootzoneProvider>
                <Rootzone />
            </RootzoneProvider>
        </div>
    ),
    "Location": forwardRef<HTMLDivElement>((props, ref) => <div ref={ref}><LocationCard {...props} /></div>),
    "rainfall": forwardRef<HTMLDivElement>((props, ref) => <div ref={ref}><RainfallCard {...props} /></div>),
    "crop": forwardRef<HTMLDivElement>((props, ref) => <div ref={ref}><CropCard {...props} /></div>),
    "Irrigation": forwardRef<HTMLDivElement>((props, ref) => <div ref={ref}><IrrigationCard {...props} /></div>),
    "etc_wu": forwardRef<HTMLDivElement>((props, ref) =>
        <EtcCropWaterUseProvider {...props}>
            <div ref={ref}><EtcCropWaterUse /></div>
        </EtcCropWaterUseProvider>
    ),
    "cdd": forwardRef<HTMLDivElement>((props, ref) =>
        <CumulativeDayDegreesProvider {...props}>
            <div ref={ref}><CumulativeDayDegrees /></div>
        </CumulativeDayDegreesProvider>
    ),
    "notes": forwardRef<HTMLDivElement>((props, ref) =>
        <div ref={ref}><Notes /></div>
    ),
};


function AllGraphs(props: { detailRef: any, setDetailRef: any }) {
    const graphPreference = useAppSelector(state => state.user.graphPreference)["ProbeGraphs"];

    const { locationDevicesData } = useFieldDetailContext();

    const refs: any = {
        sch_cal: useRef(null),
        soil_tot: useRef(null),
        stress: useRef(null),
        "smp&rootzone": useRef(null),
        Location: useRef(null),
        rainfall: useRef(null),
        crop: useRef(null),
        Irrigation: useRef(null),
        etc_wu: useRef(null),
        cdd: useRef(null),
        notes: useRef(null),
    };

    useEffect(() => {
        const tmpRef = graphPreference.map((item, index) => {
            if (refs[item.name].current) {
                return refs[item.name]
            }
        }).filter(Boolean);
        props.setDetailRef(tmpRef);
    }, [graphPreference, refs.stress.current, refs.etc_wu.current])
    return (
        <>
            {graphPreference?.map((item, index) => {
                if (item.name === "stress" && !locationDevicesData?.data?.data.value[0].LocationIDCanopy) return;
                if (item.name === "etc_wu" && !locationDevicesData?.data?.data.value[0].LocationIDSatellite) return;

                if (item.checked === 1 || item.checked === 3) {
                    const Component = graphPrefrencesMapping[item.name];
                    return <Component key={item.name} ref={refs[item.name]} />;
                }
            })}
        </>
    )
}

export default function FieldDetail(props: IFieldDetailProps) {

    const params = useParams();
    const { t } = useTranslation("FieldDetail")
    const [detailRef, setDetailRef] = useState<any[]>([]);
    const preference = useAppSelector(state => state.user.graphPreference);
    const navigate = useNavigate();

    if (preference === undefined) {
        let version = localStorage.getItem('version');
        localStorage.clear();
        version && localStorage.setItem('version', version);
        navigate('/');
        return null;
    }

    const graphPreference = preference["ProbeGraphs"];

    return (
        <FieldDetailProvider locationID={params.locationID ?? ''}>
            <ThemeProvider theme={theme}>
                <div className={styles.wrapper}>
                    <FieldDetailPageHeader detailRef={detailRef} />
                    {
                        graphPreference.filter(item => item.checked === 1 || item.checked === 3).length > 0
                            ? <></>
                            : <Typography display="flex" justifyContent="center" fontSize="20px">
                                {t("No graphs selected.")}
                            </Typography>
                    }
                    <GoannaDrawerProvider>
                        <CanopyStressContextProvider>
                            <FieldDetailHelpDrawer />
                            <AllGraphs detailRef={detailRef} setDetailRef={setDetailRef} />
                        </CanopyStressContextProvider>
                    </GoannaDrawerProvider>
                </div>
            </ThemeProvider>
        </FieldDetailProvider>
    );
}
