import { InputAdornment, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import GraphPageDataCard, { GraphPageDataCardType } from '../../../../../components/GraphPageDataCard/GraphPageDataCard';
import { FieldDetailDataType } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { DECIMAL_DISPLAY_PRECISION, getUnitName, ImperialToMetric, KG_IN_BALE, MetricToImperial, valueConverterImperial, valueFormatter } from '../../../../../utils/numericConversions';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useTranslation } from 'react-i18next';
import { LocationCropsByLocationIDDataType, usePutLocationCropsByLocationIDData } from '../../../../../hooks/ApiHooks/useLocationCropsByLocationIDData';

import styles from './style.module.css';
import moment from 'moment';
import { DateTime } from 'luxon';
import { AEST_Zone, aestToLocationLocalTimeConverter, locationLocalToAESTTimeConverter } from '../../../../../utils/DateConvertor';
import HelpIconButton from '../HelpIconButton/HelpIconButton';

export default function CropCard() {
  const { t } = useTranslation("CropCard");

  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
  const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();

  const [putLocationCropsByLocationIDData] = usePutLocationCropsByLocationIDData();

  const locationDevicesData = FieldDetailContext.locationDevicesData.data?.data.value[0] ?? {};
  const LocationCropReportSummaryByLocationIDData = FieldDetailContext.LocationCropReportSummaryByLocationIDData.data?.data[0] ?? {};
  const SoilProbesData = FieldDetailContext.soilProbesData.data?.data.value[0] ?? {};


  const loading: boolean = FieldDetailContext.locationDevicesData.isLoading
    || (FieldDetailContext.LocationCropReportSummaryByLocationIDData.isLoading && FieldDetailContext.LocationCropReportSummaryByLocationIDData.isFetched)
    || FieldDetailContext.LocationCropsByLocationIDData.isLoading
    || FieldDetailContext.soilProbesData.isLoading
    || isLoading

  // V1 logic copied
  let latestindex: number = 0;
  let lateststartdate: string | null = null;

  FieldDetailContext.LocationCropsByLocationIDData.data?.data.value.forEach(
    function (value, index) {
      if (lateststartdate == null || DateTime.fromISO(lateststartdate, { zone: AEST_Zone }) < DateTime.fromISO(value.StartDate, { zone: AEST_Zone })) {
        lateststartdate = value.StartDate;
        latestindex = index
      }
    });

  const daydegreesPresent = useMemo(() => locationDevicesData.LocationIDSatellite > 0 || locationDevicesData.WeatherStationID, [locationDevicesData]);
  const NDVIPresent = useMemo(() => locationDevicesData.LocationIDSatellite > 0, [locationDevicesData]);

  const LocationCrop = FieldDetailContext.LocationCropsByLocationIDData.data?.data.value[latestindex] ?? {} as LocationCropsByLocationIDDataType

  const [plantingDate, setPlantingDate] = useState(
    aestToLocationLocalTimeConverter(LocationCrop.PlantDate, SoilProbesData.GMTDifference)
  )
  const [salesPrice, setSalesPrice] = useState(LocationCrop.SalePrice)
  const [cropYield, setCropYield] = useState(LocationCrop.Yield)

  const onSave = useCallback(() => {
    setHasChanged(false);
    setIsLoading(true);
    let hasPlantDateChanged: boolean = moment(locationLocalToAESTTimeConverter(plantingDate, SoilProbesData.GMTDifference)).format("yyyy-MM-DD") !== LocationCrop.PlantDate.substring(0,10);
    putLocationCropsByLocationIDData(LocationCrop.LocationCropID, FieldDetailContext.locationID, {
      CropID: LocationCrop.CropID,
      LocationCropID: LocationCrop.LocationCropID,
      LocationID: FieldDetailContext.locationID,
      PlantDate: moment(locationLocalToAESTTimeConverter(plantingDate, SoilProbesData.GMTDifference)).format("yyyy-MM-DDT00:00:00"),
      EndDate: LocationCrop.EndDate,
      SalePrice: salesPrice,
      Yield: numericSystem === "M" ? cropYield : ImperialToMetric(cropYield, "Bale(s) / Acre"),
    },
      LocationCrop,
      () => {
        if (hasPlantDateChanged)
          window.location.reload();
        else
          FieldDetailContext.LocationCropReportSummaryByLocationIDData.refetch()
            .then(() => { setIsLoading(false) })
      })
  }, [LocationCrop, FieldDetailContext.locationID, plantingDate, salesPrice, cropYield]);

  useEffect(() => {
    setPlantingDate(
      aestToLocationLocalTimeConverter(LocationCrop.PlantDate, SoilProbesData.GMTDifference)
    );
    setSalesPrice(LocationCrop.SalePrice);
    setCropYield(numericSystem === "M" ? LocationCrop.Yield : MetricToImperial(LocationCrop.Yield, "Bale(s) / Ha"));
  }, [LocationCrop, SoilProbesData]);
  const CropCard: GraphPageDataCardType = {
    title: 'Crop',
    items: [
      { title: t("CropCard-CurrentCrop"), action: locationDevicesData.CropName },
      {
        title: t("CropCard-PlantingDate"),
        action: <LocalizationProvider dateAdapter={AdapterLuxon} >
          <DesktopDatePicker
            onChange={(e) => { setHasChanged(true); setPlantingDate(e?.toString() ?? null) }}
            format={numericSystem == "M" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
            value={plantingDate ? DateTime.fromISO(plantingDate, {setZone: true}) : null}
            className={styles["text-field-width"]}
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
          />
        </LocalizationProvider>
      },
      { title: t("CropCard-ETcTotal"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.ETc, "mm / Ha", true) },
      { title: t("CropCard-CumulativeEtc"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.ETc ? LocationCropReportSummaryByLocationIDData.ETc / 100 : undefined, "mm", true) },
      { title: t("CropCard-RainTotal"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.Rainmm, "mm", true) },
      { title: t("CropCard-DayDegrees"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.DayDegrees, "DD") },
      { title: t("CropCard-HeatShockDays"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.ShockHeatCount, "") },
      { title: t("CropCard-ColdShockDays"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.ShockColdCount, "") },
      { title: t("CropCard-AirTempMax"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.MaxAirTemp, "°C", true) },
      { title: t("CropCard-AirTempMin"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.MinAirTemp, "°C", true) },
      {
        title: t("CropCard-Price"),
        action: (
          <TextField
            size="small"
            className={styles["text-field-width"]}
            value={salesPrice ?? ''}
            onChange={(e) => {
              setHasChanged(true);
              setSalesPrice(parseFloat(e.target.value) || 0);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              endAdornment: (
                <InputAdornment position="end">{getUnitName(numericSystem, LocationCrop.CropID === 1 ? " / Bale(s)" : " / Tonne")}</InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        title: t("CropCard-ActualYield"),
        action: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              size="small"
              value={(cropYield === undefined || cropYield === null) ? '' : cropYield}
              onChange={(e) => {
                setHasChanged(true);
                setCropYield(parseFloat(e.target.value) || 0);
              }}
              className={styles["text-field-width"]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getUnitName(numericSystem, LocationCrop.CropID === 1 ? "Bale(s) / Ha" : "Tonne / Ha")}
                  </InputAdornment>
                ),
              }}
            />
            <Typography fontSize={"10px"}>{` (1 Bale = ${valueConverterImperial(numericSystem, 500, "lbs")}${getUnitName(numericSystem, "kg")})`}</Typography>
          </div>
        ),
      },
      { title: t("CropCard-TargetYield"), action: "-" },
      { title: t("CropCard-ETcWUP1"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.WUE, "kg / mm", true) },
      {
        title: t("CropCard-ETcWUP2"),
        action: valueFormatter(numericSystem,
          // this is v1 logic, yes this is confusing, yes it works
          LocationCropReportSummaryByLocationIDData.WUE
            ? (
              (LocationCrop.CropID === 1 && (daydegreesPresent || NDVIPresent))
                ? (LocationCropReportSummaryByLocationIDData.WUE / KG_IN_BALE * 100).toFixed(DECIMAL_DISPLAY_PRECISION)
                : undefined
            )
            : undefined, "Bales / ML"
        )
      },
      { title: t("CropCard-ETcWUP3"), action: valueFormatter(numericSystem, LocationCropReportSummaryByLocationIDData.WUEmm, "$ / mm", true) },
    ]
  }

  return (
    <GraphPageDataCard
      title={<>
        {CropCard.title}
        {/* <HelpIconButton
          content={[{
            title: "Water use efficiency (WUE)",
            content: "The ratio of crop yield (or biomass) to the amount of water used by the crop. It is an important indicator of crop productivity and water management efficiency."
          }]}
        /> */}
      </>}
      items={CropCard.items}
      showSaveButton={hasChanged}
      onSubmit={onSave}
      loading={loading}
      id="CropCard"
    />
  );
}