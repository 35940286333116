import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { forwardRef } from 'react';
import { CSSProperties } from 'react';

export interface IFieldDetailBodyProps {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
  loading: boolean
}

const FieldDetailBody = forwardRef<HTMLDivElement, IFieldDetailBodyProps>((props, ref) => {
  return (
    <div
      className={props.className ?? ''}
      ref={ref}
      style={{
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '80px',
        ...props.style,
      }}
    >
      {
        props.loading ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: "500px",
            marginTop: "3%",
            alignItems: "center"
          }}>
            <CircularProgress />
          </div>
          : props.children
      }
    </div>
  );
});

export default FieldDetailBody;
