
import { EChartsOption } from 'echarts';
import * as React from 'react';
import { useSelector } from 'react-redux';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useCumulativeDayDegreesContext from '../../../../../hooks/ContextHooks/useCumulativeDayDegreesContext';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { RootState } from '../../../../../stores/store';
import * as url from '../../../../../assets/images/MarkPoint/Balloon2.png'
import { useTooltipFormatterWithDateFormat, valueFormatter } from '../../../../../utils/Echarts';
import { getUnitName } from '../../../../../utils/numericConversions';

import styles from './style.module.css';
import { FieldDetailDataType } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import moment from 'moment';
import { XAXisOption, SeriesOption } from 'echarts/types/dist/shared';
import HelpIconButton from '../HelpIconButton/HelpIconButton';

export interface ICumulativeDayDegreesProps {
}

export default function CumulativeDayDegrees(props: ICumulativeDayDegreesProps) {

  const { dateRange } = useFieldDetailContext();
  const { loading, CumulativeDayDegreesGraphSeries } = useCumulativeDayDegreesContext();
  const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();
  const CropName = FieldDetailContext.locationDevicesData.data?.data.value[0].CropName ?? undefined;
  const isChickpeas = CropName !== undefined && CropName.includes('Chickpeas');

  const lastDataIndex = CumulativeDayDegreesGraphSeries?.DayDegreesCumulative?.data.length - 1;
  const lastDataValue = CumulativeDayDegreesGraphSeries?.DayDegreesCumulative?.data[lastDataIndex] ?? null;
  const lastForecastIndex = CumulativeDayDegreesGraphSeries?.DayDegreesCumulativeForecast?.data.length - 1;
  const lastForecastValue = CumulativeDayDegreesGraphSeries?.DayDegreesCumulativeForecast?.data[lastForecastIndex] ?? null;
  const numericSystem = useSelector((state: RootState) => state.user.numericSystem);
  const temperatureUnit = getUnitName(numericSystem, "\u00B0C");
  const temperatureText = `Temperature(${temperatureUnit})`;

  const [GrowthStages, setGrowthStages] = React.useState<string[]>([]);

  const GrowStageSeries: SeriesOption[] = React.useMemo(() => {
    const colors = ['#1BD183', '#1085FD', '#E8923C', '#1A1989', '#FC282A', '#5A8B54', '#151515', '#FDAB3D', '#FDB5C3', '#85EF81', '#A1D1E0', '#f0f00c', '#bebebe', '#d3a6d3', '#910c0c'];
    const obj = CumulativeDayDegreesGraphSeries.GrowthStages;

    const series: SeriesOption[] = [];
    if (obj) {
      Object.keys(obj).forEach((key, index) => {
        setGrowthStages((preState) => [...preState, key])
        series.push({
          name: key,
          type: 'line',
          showSymbol: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          tooltip: {
            show: false
          },
          data: CumulativeDayDegreesGraphSeries?.MaxAirTemp?.data ?? [],
          itemStyle: {
            color: colors[index % colors.length]
          },
          lineStyle: {
            color: colors[index % colors.length],
            opacity: 0,
          },
          markLine: {
            data: [
              {
                name: key,
                yAxis: obj[key] ?? '',
                // yAxis: 300,
                lineStyle: {
                  color: colors[index % colors.length],
                  width: 3
                },
              },
            ],
            symbol: ['none', 'none'],
          }
        },);
      })
    }
    return series;
  }, [CumulativeDayDegreesGraphSeries?.GrowthStages, CumulativeDayDegreesGraphSeries?.MaxAirTemp?.data]);

  const columnNames = [
    'Max Temp',
    'Min Temp',
    'Avg Temp',
    'Min Temp Forecast',
    'Max Temp Forecast',
    'Avg Temp Forecast',
  ];

  if (isChickpeas) {
    columnNames.push('Flowering');
  }

  const columnNames2 = [
    'Cumulative Day Degrees Forecast',
    'Cumulative Day Degrees',
  ]

  const formatter = useTooltipFormatterWithDateFormat({
    [columnNames[0]]: getUnitName(numericSystem, "°C"),
    [columnNames[1]]: getUnitName(numericSystem, "°C"),
    [columnNames[2]]: getUnitName(numericSystem, "°C"),
    [columnNames[3]]: getUnitName(numericSystem, "°C"),
    [columnNames[4]]: getUnitName(numericSystem, "°C"),
    [columnNames[5]]: getUnitName(numericSystem, "°C"),
    [columnNames[6]]: getUnitName(numericSystem, "°C"),
    [columnNames[7]]: "",
    [columnNames2[0]]: '',
    [columnNames2[1]]: '',
  }, false);

  const [legendSelectionModel, setLegendSelectionModel] = React.useState<{ [x: string]: boolean; }>({
    'Avg Temp': false,
    'Avg Temp Forecast': false,
  });

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      textStyle: {
        fontWeight: 'normal'
      },
      formatter,
      axisPointer: {
        type: 'cross'
      }
    },
    legend: [
      {
        data: columnNames,
        selected: legendSelectionModel
      },
      {
        data: [...columnNames2, ...GrowthStages],
        bottom: 55
      },

    ],
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all'
        }
      ]
    },
    dataZoom: {
      type: 'slider',
      xAxisIndex: [0, 1],
    },
    grid: [
      {
        left: 90,
        right: 90,
        height: '40%'
      },
      {
        left: 90,
        right: 90,
        top: '51%',
        height: '40%'
      }
    ],
    xAxis: [
      {
        type: 'time',
        boundaryGap: false,
        min: dateRange[0]?.toISODate(),
        max: dateRange[1]?.toISODate(),
        axisPointer: {
          label: {
            formatter: function (param: any) {
              const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
              return moment(param.value).format(dateTimeFormat);
            }
          }
        },
      } as XAXisOption,
      {
        gridIndex: 1,
        show: false,
        type: 'time',
        boundaryGap: false,
        position: 'top',
        min: dateRange[0]?.toISODate(),
        max: dateRange[1]?.toISODate(),
        axisPointer: {
          label: {
            show: false,
            formatter: function (param: any) {
              const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
              return moment(param.value).format(dateTimeFormat);
            }
          }
        },
      } as XAXisOption
    ],
    yAxis: [
      {
        name: temperatureText,
        type: 'value',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
      },
      {
        name: 'Cumulative Day Degrees',
        type: 'value',
        nameLocation: 'middle',
        nameGap: 50,
        nameTextStyle: {
          fontSize: '1rem',
          color: '#000000',
        },
        scale: true,
        gridIndex: 1,
        min: CumulativeDayDegreesGraphSeries.GrowthStages && ((value) => {
          const minValue = Math.min(...Object.values(CumulativeDayDegreesGraphSeries.GrowthStages));

          if (value.min < minValue || minValue === Infinity) {
            return Number((value.min * 0.95).toFixed(1));
          }
          else {
            return Number((minValue * 0.95).toFixed(1));
          }
        }),
        max: CumulativeDayDegreesGraphSeries.GrowthStages && ((value) => {
          const dayDegreesForecast = CumulativeDayDegreesGraphSeries?.DayDegreesCumulativeForecast?.data[CumulativeDayDegreesGraphSeries.DayDegreesCumulativeForecast.data.length - 1] ?? null
          var dayDegreesForecastValue = null;
          if (Array.isArray(dayDegreesForecast) && dayDegreesForecast[1]) {
            dayDegreesForecastValue = dayDegreesForecast[1];
          }
          const maxValue = Math.max(...Object.values(CumulativeDayDegreesGraphSeries.GrowthStages));

          if (value.max > maxValue || maxValue === Infinity) {
            return Number((value.max * 1.05).toFixed(1));
          }
          else {
            return Number((maxValue * 1.05).toFixed(1));
          }

        }),
      }
    ],
    color: [
      '#7B7441',
      '#E22C29',
      '#E8923C',
      '#2D9C41',
      '#5809A7',
      '#890A36',
      '#1BD183',
      '#890A36',
    ],
    series: [
      //for heat shock
      {
        name: 'Max Temp',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.MaxAirTemp?.data ?? [],
        smooth: false,
        lineStyle: {
          color: '#7B7441'
        },
        showSymbol: true,
        emphasis: {
          scale: 100,
        },
        itemStyle: {
          color: '#7B7441',
          // borderWidth: 2,
          // borderColor: '#FFFFFF'
        },
        symbol: 'circle',

        symbolSize(rawValue) {
          const isInShock = numericSystem === 'M' ? rawValue[1] >= 35 : numericSystem === 'I' && rawValue[1] >= 95;
          return isInShock ? 0 : 0.1;
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Max Temp',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.MaxAirTemp?.data ?? [],
        smooth: false,
        lineStyle: {
          color: '#7B7441'
        },
        showSymbol: true,
        itemStyle: {
          color(params: any) {
            const isInShock = numericSystem === 'M' ? params.value[1] >= 35 : numericSystem === 'I' && params.value[1] >= 95;
            return isInShock ? '#FF0000' : '#7B7441';
          },
          // borderWidth: 2,
          // borderColor: '#FFFFFF'
        },
        symbol: 'emptyCircle',
        symbolSize(rawValue) {
          const isInShock = numericSystem === 'M' ? rawValue[1] >= 35 : numericSystem === 'I' && rawValue[1] >= 95;
          return isInShock ? 10 : 0;
        },
        tooltip: {
          show: false,
        },
      },
      {
        name: 'Cumulative Day Degrees',
        type: 'line',
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },

        emphasis: {
          scale: 100,
        },
        data: CumulativeDayDegreesGraphSeries?.DayDegreesCumulative?.data ?? [],
        markPoint: {
          data: lastDataValue ? [
            {
              name: 'Cumulative Day Degrees',
              coord: lastDataValue,
              value: lastDataValue ? lastDataValue[1] : null,
            },
          ]
            :
            [],
          label: {
            formatter: '{c}',
            color: 'black',
            offset: [0, 5],
          },
          symbol: `image://${url.default}`,
          symbolSize: 60,
          symbolOffset: [1, 30],
          symbolRotate: 180,
          emphasis: {
            disabled: true
          }
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      //for cold shock
      {
        name: 'Min Temp',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.MinAirTemp?.data ?? [],
        smooth: false,
        showSymbol: true,
        emphasis: {
          scale: 100,
        },
        lineStyle: {
          color: '#E8923C'
        },
        symbolSize(rawValue) {
          const isInShock = numericSystem === 'M' ? rawValue[1] <= 11 : numericSystem === 'I' && rawValue[1] <= 51.8;
          return isInShock ? 0 : 0.1
        },
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Min Temp',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.MinAirTemp?.data ?? [],
        smooth: false,
        showSymbol: true,
        lineStyle: {
          color: '#FFFFFF00'
        },
        itemStyle: {
          color(params: any) {
            const isInShock = numericSystem === 'M' ? params.value[1] <= 11 : numericSystem === 'I' && params.value[1] <= 51.8;
            return isInShock ? '#0000FF' : '#F29C46';
          },
        },
        symbolSize(rawValue) {
          const isInShock = numericSystem === 'M' ? rawValue[1] <= 11 : numericSystem === 'I' && rawValue[1] <= 51.8;
          return isInShock ? 10 : 0
        },
        symbol: 'emptyCircle',
        tooltip: {
          show: false,
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Avg Temp',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.AvgAirTemp?.data ?? [],
        smooth: false,
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        emphasis: {
          scale: 100,
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Min Temp Forecast',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.AirTempMinForecast?.data ?? [],
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        emphasis: {
          scale: 100,
        },
        lineStyle: {
          type: 'dashed'
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Max Temp Forecast',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.AirTempMaxForecast?.data ?? [],
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        emphasis: {
          scale: 100,
        },
        lineStyle: {
          type: 'dashed'
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Avg Temp Forecast',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.AirTempAvgForecast?.data ?? [],
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        emphasis: {
          scale: 100,
        },
        lineStyle: {
          type: 'dashed'
        },
        tooltip: {
          valueFormatter: (value: any) => {
            return valueFormatter(value, temperatureUnit)
          }
        },
      },
      {
        name: 'Cumulative Day Degrees Forecast',
        type: 'line',
        data: CumulativeDayDegreesGraphSeries?.DayDegreesCumulativeForecast?.data ?? [],
        smooth: false,
        xAxisIndex: 1,
        yAxisIndex: 1,
        showSymbol: true,
        symbolSize: 0.1,
        symbol: 'circle',
        // itemStyle: {
        //   borderWidth: 2,
        //   borderColor: '#FFFFFF'
        // },
        emphasis: {
          scale: 100,
        },
        lineStyle: {
          type: 'dashed'
        },
        markPoint: {
          data: lastForecastValue ? [
            {
              name: 'Cumulative Day Degrees Forecast',
              coord: lastForecastValue,
              value: lastForecastValue ? lastForecastValue[1] : null,
            },
          ]
            :
            [],
          label: {
            formatter: '{c}',
            color: 'black',
            offset: [0, 5],
          },
          symbol: `image://${url.default}`,
          symbolSize: 60,
          symbolOffset: [1, 30],
          symbolRotate: 180,
          emphasis: {
            disabled: true
          }
        },
      },
      isChickpeas ? {
        //this is hard coded
        name: 'Flowering',
        type: 'line',
        showSymbol: false,
        tooltip: {
          show: false
        },
        data: CumulativeDayDegreesGraphSeries?.MaxAirTemp?.data ?? [],
        itemStyle: {
          color: '#b2e630'
        },
        lineStyle: {
          color: '#FF0000',
          opacity: 0,
        },
        markLine: {
          data: [
            {
              name: 'Refill point',
              yAxis: 15,
              lineStyle: {
                width: 1,
                color: '#b2e630'
              },
            }
          ],
          symbol: ['none', 'none'],
        }
      } : undefined as any,
      ...GrowStageSeries
    ]
  };

  const shock = React.useMemo(() => {
    const { heatShock, coldShock } = CumulativeDayDegreesGraphSeries;
    const hasHeatShock = heatShock > 0;
    const hasColdShock = coldShock > 0;

    if (!hasHeatShock && !hasColdShock) return '';
    let text = '';

    if (hasHeatShock && !hasColdShock) {
      text += `(${heatShock} days of heat shock)`
    }
    else if (!hasHeatShock && hasColdShock) {
      text += `(${coldShock} days of cold shock)`
    }
    else if (hasHeatShock && hasColdShock) {
      text += `(${heatShock} days of heat shock , ${coldShock} days of cold shock)`
    }
    return text;
  }, [CumulativeDayDegreesGraphSeries])

  return (
    <FieldDetailPaper width='100%' id='CumulativeDayDegreesGraph'>
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.CumulativeDayDegreesHeader}>
          <div className={styles.headerLeft}>
            <span>Cumulative Day Degrees</span>
            <span className={styles.shockDays}>{shock}</span>
            <HelpIconButton
              content={[{
                title: "Cumulative Day Degrees",
                content: "Cumulative day degree (CDD) is a measure of the total amount of heat accumulation over a certain period. It is like growing degree days (GDD), which is the sum of the differences between the average daily temperature and a base temperature over a given period of time."
              }]}
            />
          </div>
        </div>
      </FieldDetailHeader>
      <FieldDetailBody className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px' }} loading={loading}>
        <ReactECharts onLegendChange={({ selected }: any) => { setLegendSelectionModel(selected) }} option={option} style={{ height: '1000px', width: '100%' }} loading={loading} />
      </FieldDetailBody>
    </FieldDetailPaper>
  );
}