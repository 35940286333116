import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store, persistor } from './stores/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { client, secondaryClient } from './api/apollo';
import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './api/reactQuery';
import { LicenseInfo } from '@mui/x-license-pro';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import LogRocket from 'logrocket';
import logrocketConfig from './LogrocketConfig';

LicenseInfo.setLicenseKey('1f9fa406fa66b2d44ca00bbf42701ee7Tz03MjgxNCxFPTE3MjM3OTEyMzIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
LogRocket.init('l04aow/goapp-v2-dev-uqdmw');

if (logrocketConfig.enabled) {
  LogRocket.init(logrocketConfig.init);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <ApolloProvider client={secondaryClient}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </React.StrictMode>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ApolloProvider>
  </ApolloProvider>,
);
